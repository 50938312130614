import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  viewbox: PropTypes.string,
  width: PropTypes.number,
}

const defaultProps = {
  className: 'menu__icon',
  viewBox: '0 0 140 100',
  width: 140,
  height: 100,
}

/**
 * SVG Card Icon
 */
const EnvelopeTryAgain = (props) => {
  const { height, viewBox, width } = props
  return (
    <svg width={width} height={height} viewBox={viewBox}>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Payer--Quick-link-not-valid' transform='translate(-642.000000, -287.000000)'>
          <g id='Group-5' transform='translate(642.000000, 287.000000)'>
            <g id='Group-2' transform='translate(0.000000, 5.000000)'>
              <path
                d='M81.5380605,57.9864865 L115.885714,91.2570604 C114.210219,93.3364713 112.534724,95 109.602607,95 L109.602607,95 L7.39739312,95 C4.46527633,95 2.78978102,93.3364713 1.11428571,91.2570604 L1.11428571,91.2570604 L36.718561,57.9864865 L48.865902,67.5517765 C54.7301356,72.1264804 63.5264859,72.1264804 69.3907195,67.5517765 L69.3907195,67.5517765 L81.5380605,57.9864865 Z M117,28.8243243 L117,83.7837838 L86.9142857,53.534779 L117,28.8243243 Z M0,28.8243243 L31.2,53.534779 L0,83.7837838 L0,28.8243243 Z M109.478571,12 C114.910714,12 117,16.2771778 117,20.9820734 L117,20.9820734 L65.6035714,62.4706979 C61.8428571,65.4647223 56.4107143,65.4647223 52.65,62.4706979 L52.65,62.4706979 L0,20.9820734 C0,16.2771778 2.50714286,12 7.52142857,12 L7.52142857,12 Z'
                id='Combined-Shape'
                fill='#CDCDCD'
              />
              <g id='Group-3' transform='translate(88.000000, 0.000000)' />
            </g>
            <circle id='Oval' fill='#FFFFFF' cx='112' cy='29' r='17' />
            <path
              d='M111.5,-3.55271368e-15 C116.926689,-3.55271368e-15 122.120242,1.52687707 126.577642,4.31049334 L127.036106,4.60292558 L127.355,4.815 L127.582254,4.58861357 C132.054347,0.116520663 139.624179,3.0998073 139.986499,9.26943677 L139.996687,9.50228314 L140,9.732351 L140,22.4354839 C140,24.6573594 139.003841,26.6465663 137.433777,27.9808494 C139.048402,29.345362 139.936052,31.5191758 139.506651,33.8010483 C136.987949,47.1756191 125.263657,57 111.5,57 C106.073311,57 100.879758,55.4731229 96.422358,52.6895067 L95.9638943,52.3970744 L95.644,52.185 L95.4177459,52.4113864 C90.945653,56.8834793 83.3758212,53.9001927 83.0135009,47.7305632 L83.0033126,47.4977169 L83,47.267649 L83,34.5645161 C83,32.3426406 83.9961593,30.3534337 85.5662225,29.0191506 C83.9517319,27.6548483 83.0640326,25.4810522 83.4934312,23.1991923 C86.0120076,9.82445564 97.7362762,-3.55271368e-15 111.5,-3.55271368e-15 Z'
              id='Path'
              fill='#FFFFFF'
              fillRule='nonzero'
            />
            <path
              d='M90.2741935,32.2903226 L90.1184878,32.2955692 C88.9865567,32.3721054 88.0817828,33.2768793 88.0052466,34.4088103 L88,34.5645161 L88,47.267649 L88.0050259,47.425484 C88.0882649,48.7228271 89.1922488,49.5660029 90.3298958,49.5461601 L90.4665119,49.5396103 C90.9118938,49.5045909 91.3563855,49.3350557 91.7409322,49.0065441 L91.882212,48.8758525 L95.2664815,45.4915831 L95.5857061,45.7910409 C99.6616285,49.5442698 105.067713,51.8750509 111.016493,51.9951244 L111.5,52 L111.88206,51.996957 C123.194902,51.8166342 132.565457,43.6422935 134.593022,32.8757127 C134.716695,32.2185028 134.245917,31.6085081 133.597754,31.539018 L133.474247,31.5324431 L128.044166,31.5324431 L127.924654,31.5387181 C127.45258,31.588536 127.057734,31.9303957 126.940197,32.3995164 C125.189764,39.383667 118.850783,44.4127847 111.514806,44.4194474 C107.572272,44.4230255 103.84825,43.0023423 100.928528,40.4019777 L100.629381,40.1287763 L104.58553,36.1726271 L104.693576,36.0574657 C105.928794,34.6527805 105.004764,32.4186635 103.139484,32.2956297 L102.977326,32.2903226 L90.2741935,32.2903226 Z'
              id='Path'
              fill='#39AA75'
              fillRule='nonzero'
            />
            <path
              d='M111.489977,17.5806432 C106.58622,17.5849709 102.285343,20.8798148 100.984333,25.5349171 L100.909891,25.8158401 C100.778579,26.3399426 100.58206,26.8344969 100.33018,27.291487 L102.977326,27.2903226 C109.06814,27.2903226 112.301368,34.2111929 108.864005,38.8483102 L108.7,39.06 L108.782434,39.082351 C109.42354,39.2453676 110.083269,39.3503324 110.755829,39.3949022 L111.160857,39.4143779 L111.510265,39.4194494 C116.413101,39.4149966 120.714436,36.1207796 122.015655,31.4652923 L122.090109,31.184345 C122.221421,30.6602425 122.41794,30.1656881 122.66982,29.7086981 L120.022674,29.7096774 C113.93186,29.7096774 110.698632,22.7888071 114.136029,18.1516555 L114.299,17.939 L114.217703,17.91778 C113.576624,17.7547589 112.916923,17.6497871 112.244388,17.6052056 L111.839373,17.5857222 L111.489977,17.5806432 Z'
              id='Path'
              fill='#FFFFFF'
              fillRule='nonzero'
            />
            <path
              d='M134.994974,9.57451599 C134.875203,7.70778342 132.642079,6.78135948 131.236198,8.01324337 L131.117788,8.12414748 L127.733519,11.5084169 L127.414294,11.2089591 C123.228211,7.35429158 117.639094,5 111.5,5 C100.0166,5 90.4573246,13.2367337 88.407071,24.1244723 C88.2833978,24.7816822 88.7542577,25.391677 89.4023526,25.461167 L89.5258454,25.4677419 L94.9558342,25.4677419 L95.075346,25.461467 C95.5474205,25.4116491 95.9422662,25.0697894 96.0598026,24.6006686 C97.7815405,17.7304739 103.945791,12.7473769 111.136824,12.5847403 L111.485564,12.5806452 L111.892599,12.5853545 C115.683864,12.6765375 119.252448,14.0875076 122.071473,16.5981238 L122.370619,16.8713162 L118.41447,20.8273729 L118.306424,20.9425343 C117.071206,22.3472195 117.995236,24.5813365 119.860516,24.7043703 L120.022674,24.7096774 L132.725806,24.7096774 L132.881512,24.7044308 C134.013443,24.6278946 134.918217,23.7231207 134.994753,22.5911897 L135,22.4354839 L135,9.732351 L134.994974,9.57451599 Z'
              id='Path'
              fill='#39AA75'
              fillRule='nonzero'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

EnvelopeTryAgain.displayName = 'EnvelopeTryAgain'
EnvelopeTryAgain.propTypes = propTypes
EnvelopeTryAgain.defaultProps = defaultProps

export default EnvelopeTryAgain
