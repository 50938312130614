import React from 'react'
import classNames from 'classnames'

interface AuthFormProps {
  title: string
  description?: string
  linkTitle?: string
  link?: string
  action?: () => void
  children: React.ReactNode
  isButton?: boolean
  linkClassName?: string
  LinkComponent?: React.FC<{
    to: string
    children: React.ReactNode
    onClick?: () => void
    className?: string
  }>
}

export const AuthForm: React.FC<AuthFormProps> = (props) => {
  const {
    title,
    description,
    linkTitle,
    link,
    action,
    children,
    isButton = false,
    linkClassName,
    LinkComponent,
  } = props
  const linkClasses = classNames('auth-link', linkClassName)

  return (
    <div className='auth-form'>
      <div className='auth-header'>
        <div className='auth-title'>{title}</div>
        {description && <div className='auth-title__description'>{description}</div>}
      </div>
      {children}
      {link && (
        <div className='fields-container--center-align'>
          <div className={linkClasses} onClick={isButton ? action : () => {}}>
            <LinkComponent to={link} onClick={!isButton ? action : () => {}}>
              {linkTitle}
            </LinkComponent>
          </div>
        </div>
      )}
    </div>
  )
}
