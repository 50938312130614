import React, { Fragment } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'

import { LoadingMask } from '@elo-kit/components/loading-mask/LoadingMask'
import { EloButton } from '@elo-kit/components/elo-button/EloButton'
import { TextField } from '@elo-kit/components/elo-ui/text-field/TextField'
import EnvelopeSuccess from 'ui/icons/EnvelopeSuccess'
import EnvelopeTryAgain from 'ui/icons/EnvelopeTryAgain'

import { AuthForm } from './AuthForm'
import { useSignIn } from './hooks/useSignIn'

interface SignInPaageProps {
  screenSize?: string
  LinkComponent?: React.FC<{
    to: string
    children: React.ReactNode
    onClick?: () => void
    className?: string
  }>
}

const SignIn: React.FC<SignInPaageProps> = observer(({ screenSize, LinkComponent }) => {
  const [
    {
      data,
      isValid,
      formTitle,
      formDescription,
      linkTitle,
      link,
      linkNotExpiredAndNotSent,
      loading,
      isEmailValid,
      isPasswordValid,
      isMagicLinkAppActive,
      touched,
      confirmationPageLink,
    },
    { handleInput, handleSubmit, toggleMagicLink },
  ] = useSignIn(screenSize)

  const { email, password, loginViaMagicLink, isMagicLinkExpired, isMagicLinkSent } = data

  const emailWrapperClasses = classNames({
    'fields-container--center-align': loginViaMagicLink,
  })
  const emailFieldClasses = classNames({
    'auth-field__email--sign-in auth-field': !loginViaMagicLink,
    [`
      auth-field
      fields-container--60-percentage
      auth-field--with-margins
      fields-container--mobile-100
    `]: loginViaMagicLink,
  })

  const horisontalSeparatorClasses = classNames(
    'fields-container--49-percentage',
    'fields-container__horisontal-separator',
    'fields-container__horisontal-separator--no-margin',
    'fields-container__horisontal-separator--dark'
  )

  const linkClasses = classNames({
    'magic-link-btn magic-link-btn--request-new': isMagicLinkExpired,
  })

  return (
    <div className='shop-auth'>
      <div className='fields-container--center-align'>
        {loading ? (
          <LoadingMask />
        ) : (
          <div className='auth-form__container'>
            <AuthForm
              title={formTitle}
              description={formDescription}
              linkTitle={linkTitle}
              link={(loginViaMagicLink || isMagicLinkExpired) && link}
              action={(loginViaMagicLink || isMagicLinkExpired || isMagicLinkSent) && toggleMagicLink}
              isButton={Boolean(isMagicLinkExpired)}
              linkClassName={linkClasses}
              LinkComponent={LinkComponent}
            >
              {linkNotExpiredAndNotSent && (
                <div className={emailWrapperClasses}>
                  <TextField
                    prefixNormalField
                    className={emailFieldClasses}
                    placeholder={I18n.t('react.shop.form.email')}
                    onChange={(value) => handleInput('email', value)}
                    value={email}
                    isInvalid={touched.email && !isEmailValid}
                    errorMessage={I18n.t('react.shared.validations.invalid_email')}
                    prefix={<i className='fas fa-envelope prefix-icon' />}
                    inputId='sign-in-email-text-field'
                  />
                </div>
              )}
              {!loginViaMagicLink && linkNotExpiredAndNotSent && (
                <TextField
                  required
                  withHidePassword
                  prefixNormalField
                  type='password'
                  className='auth-field__password--sign_in auth-field'
                  placeholder={I18n.t('react.shop.form.password')}
                  onChange={(value) => handleInput('password', value)}
                  value={password}
                  isInvalid={touched.password && !isPasswordValid}
                  prefix={<i className='fas fa-lock prefix-icon' />}
                  inputId='sign-in-password-text-field'
                />
              )}
              {loginViaMagicLink && linkNotExpiredAndNotSent && (
                <div className='fields-container fields-container--column'>
                  <EloButton
                    className='auth-form__btn fields-container--49-percentage fields-container--mobile-100'
                    onClick={handleSubmit}
                    disabled={loginViaMagicLink && !isValid}
                    orange={loginViaMagicLink}
                  >
                    <i className='fas fa-link' />
                    {I18n.t('react.shop.auth.sign_in.send_magic_link')}
                  </EloButton>
                </div>
              )}
              {(isMagicLinkSent || isMagicLinkExpired) && (
                <div className='fields-container--center-align auth-envelope'>
                  {isMagicLinkSent ? <EnvelopeSuccess /> : <EnvelopeTryAgain />}
                </div>
              )}
              {!loginViaMagicLink && linkNotExpiredAndNotSent && (
                <Fragment>
                  <div className='elo-btn-container elo-btn-container--centered'>
                    <EloButton
                      className='auth-form__btn fields-container--60-percentage'
                      disabled={!isValid}
                      onClick={handleSubmit}
                      orange
                    >
                      <i className='fas fa-user-circle' />
                      {I18n.t('react.shop.auth.sign_in.submit')}
                    </EloButton>
                  </div>

                  <div className='fields-container--center-align fields-container--column fields-container'>
                    <div className='auth-link'>
                      <LinkComponent to={link}>{I18n.t('react.shop.auth.sign_in.forgot_password')}</LinkComponent>
                    </div>
                    <div className='auth-link'>
                      <LinkComponent to={confirmationPageLink}>
                        {I18n.t('react.shop.auth.sign_in.resend_mail_confirmation')}
                      </LinkComponent>
                    </div>
                  </div>

                  {isMagicLinkAppActive && (
                    <Fragment>
                      <div className='fields-container fields-container--with-two-separators'>
                        <div className={horisontalSeparatorClasses} />
                        <div className='fields-container__text-with-separators'>{I18n.t('react.shared.or')}</div>
                        <div className={horisontalSeparatorClasses} />
                      </div>
                      <div className='auth-header'>
                        <div className='auth-title magic-link__title'>
                          {I18n.t('react.shop.auth.sign_in.use_magic_link_title')}
                        </div>
                        <div className='auth-title__description magic-link__description'>
                          {I18n.t('react.shop.auth.sign_in.use_magic_link_description')}
                        </div>
                      </div>
                      <div className='elo-btn-container elo-btn-container--centered'>
                        <EloButton className='auth-form__btn fluid' onClick={toggleMagicLink} orange>
                          <i className='fas fa-magic' />
                          {I18n.t('react.shop.auth.sign_in.magic_link')}
                        </EloButton>
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </AuthForm>
          </div>
        )}
      </div>
    </div>
  )
})

export default SignIn
