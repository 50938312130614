import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  viewbox: PropTypes.string,
  width: PropTypes.number,
}

const defaultProps = {
  className: 'menu__icon',
  viewBox: '0 0 140 100',
  width: 140,
  height: 100,
}

/**
 * SVG Card Icon
 */
const EnvelopeSuccess = (props) => {
  const { height, viewBox, width } = props
  return (
    <svg width={width} height={height} viewBox={viewBox}>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Payer--Quick-link-sent' transform='translate(-642.000000, -287.000000)'>
          <g id='Group' transform='translate(642.000000, 287.000000)'>
            <polygon id='Path' fill='#CDCDCD' points='117 88.7837838 117 33.8243243 86.9142857 58.534779' />
            <path
              d='M0,25.9820734 L52.65,67.4706979 C56.4107143,70.4647223 61.8428571,70.4647223 65.6035714,67.4706979 L117,25.9820734 C117,21.2771778 114.910714,17 109.478571,17 L7.52142857,17 C2.50714286,17 0,21.2771778 0,25.9820734 Z'
              id='Path'
              fill='#CDCDCD'
            />
            <path
              d='M36.718561,62.9864865 L1.11428571,96.2570604 C2.78978102,98.3364713 4.46527633,100 7.39739312,100 L109.602607,100 C112.534724,100 114.210219,98.3364713 115.885714,96.2570604 L81.5380605,62.9864865 L69.3907195,72.5517765 C63.5264859,77.1264804 54.7301356,77.1264804 48.865902,72.5517765 L36.718561,62.9864865 Z'
              id='Path'
              fill='#CDCDCD'
            />
            <polygon
              id='Path'
              fill='#CDCDCD'
              points='-1.45483625e-12 33.8243243 -1.45483625e-12 88.7837838 31.2 58.534779'
            />
            <path
              d='M111.5,0 C95.7808033,0 83,12.7808033 83,28.5 C83,44.2191967 95.7808033,57 111.5,57 C127.219197,57 140,44.2191967 140,28.5 C140,12.7808033 127.219197,0 111.5,0 Z'
              id='Path'
              fill='#FFFFFF'
              fillRule='nonzero'
            />
            <path
              d='M111.5,5 C124.457773,5 135,15.542227 135,28.5 C135,41.457773 124.457773,52 111.5,52 C98.542227,52 88,41.457773 88,28.5 C88,15.542227 98.542227,5 111.5,5 Z'
              id='Path'
              fill='#39AA75'
              fillRule='nonzero'
            />
            <path
              d='M124.227377,23.5792218 L109.562802,38.0208275 C109.11648,38.4603631 108.497885,38.7126439 107.866878,38.7126439 L107.866878,39.6904762 L107.845531,38.7126439 C107.206084,38.7072658 106.585007,38.4442289 106.141167,37.9910036 L98.7428498,30.4392045 C97.82439,29.5019522 97.8516956,28.004402 98.8034185,27.0999071 C99.252719,26.6725943 99.8440085,26.4379146 100.468065,26.4379146 C101.124888,26.4379146 101.738021,26.6941067 102.194273,27.1595548 L107.897659,32.9810796 L120.836523,20.2394354 C121.289299,19.7935439 121.891511,19.547619 122.532446,19.547619 C123.172886,19.547619 123.775098,19.793055 124.227874,20.2389465 C125.16222,21.1600646 125.16222,22.6585926 124.227377,23.5792218 Z'
              id='Path'
              fill='#FFFFFF'
              fillRule='nonzero'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

EnvelopeSuccess.displayName = 'EnvelopeSuccess'
EnvelopeSuccess.propTypes = propTypes
EnvelopeSuccess.defaultProps = defaultProps

export default EnvelopeSuccess
