import React from 'react'
import { observer } from 'mobx-react'

import { composeProps } from 'shop/utils/compose-props.utils'
import { withCommonStores } from 'shop/utils/with-common-stores.utils'
import { withRedirectToPayerCabinet } from 'shop/utils/with-redirect-to-payer-cabinet.utils'
import { useNextRouter } from 'shop/hooks/use-next-js-router'
import { withLocale } from 'shop/utils/with-locale.utils'
import { withRootStore } from 'shop/utils/with-root-store.utils'
import { withCacheControl } from 'shop/utils/cache-control.utils'
import { withGSSPLogger } from 'shop/utils/logger.utils'
import { withScreenSize } from 'shop/utils/with-screen-size.utils'
import { withUserSessionId } from 'shop/utils/with-user-session-id.utils'
import { withEnvVars } from 'shop/utils/withEnvVars'
import { withApiClient } from 'shop/utils/with-api-client.utils'
import { withLogger } from 'shop/utils/with-logger.utils'
import { withShopThemeStore } from 'shop/utils/with-shop-theme-store.utils'
import { withTranslations } from 'shop/utils/with-translations.utils'

import SignIn from 'shop/components/auth/SignIn'
import { withExperiments } from 'shop/utils/with-experiments.utils'

interface SignInPageProps {
  screenSize: string
}

const SignInPage: React.FC<SignInPageProps> = observer(({ screenSize }) => {
  const { LinkComponent } = useNextRouter()
  return <SignIn screenSize={screenSize} LinkComponent={LinkComponent} />
})

export default SignInPage

const getPageProps = () =>
  Promise.resolve({
    props: {
      metaData: { noFollow: true },
    },
  })

export const getServerSideProps = withGSSPLogger(
  composeProps(
    withLocale,
    withTranslations,
    withApiClient,
    withEnvVars,
    withUserSessionId,
    withRootStore,
    withScreenSize,
    withCommonStores,
    withShopThemeStore,
    withRedirectToPayerCabinet,
    withExperiments,
    getPageProps,
    withCacheControl(60),
    withLogger
  ),
  'getServerSideProps SignInPage'
)
